import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/Seo"

const InformationPage = () => {
  const data = useStaticQuery(graphql`
    query {
      currentBuildDate {
        currentDate
      }
      site {
        siteMetadata {
          instagram
          title
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Information" />
      <section className="container 3xl:max-w-screen-3xl max-w-screen-md p-5 sm:p-8 sm:text-xl 3xl:text-5xl font-semibold markdown text-brand-white">
        <p>
          Swiss-born, Paris-based photographer Benoît Peverelli is known for his
          broad approach to photography. Whether in fashion, portraiture,
          landscape or documentary, his work displays photography’s essence as
          being the central core of his practice.
        </p>
        <p>
          Trained as a photojournalist, Peverelli started at a young age
          shooting for local newspapers and press agencies, he moved to New York
          in his early twenties to pursue his career and widen a vision that has
          matured into a distinct voice, mixing an ability to disappear and
          observe with a powerful ability to direct.
        </p>
        <p>
          He regularly shoots and collaborates on content for publications such
          as Vogue Paris, Elle, W, T Magazine, Purple, etc. His illustrious
          clients have included the likes of Chanel, Louis Vuitton, Fendi, Air
          France, Kering, Uniqlo, and Loewe.
        </p>
        <p>
          Peverelli has published{" "}
          <a href="https://steidl.de/Books/CHANEL-Fittings-and-Backstage-1518212855.html">
            ”Chanel, fittings and backstage”
          </a>{" "}
          with Gerhard Steidl, a four-volume book documenting more than ten years of his work at the Parisian house encompassing the last decade of Karl Lagerfeld’s and the years following his death.
        </p>
        <p style={{ opacity: 0.5, margin: 0 }}>General enquiries</p>
        <a href="mailto:studio@benoitpeverelli.com">
          studio@benoitpeverelli.com
        </a>
      </section>
      <footer className="container max-w-screen-xl font-sans p-5 sm:p-8 text-xs lg:text-sm text-brand-white text-center">
        <p>
          &copy;{data.currentBuildDate.currentDate}{" "}
          {data.site.siteMetadata.title} | Legal |{" "}
          <a
            className="transition duration-300 ease-in-out hover:opacity-50"
            href={data.site.siteMetadata.instagram}
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
        </p>
        <div id="netlify-modal"></div>
      </footer>
    </>
  )
}

export default InformationPage
